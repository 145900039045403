import React from "react"
import "./footer.css"
import logo from "../images/benner.svg"

const Footer = () => {
  return (
    <footer>
      <div className="footer__info">
        <img src={logo} alt="Logo benner" />
        <a href="mailto:hallo@nucompany.be">hallo@nucompany.be</a>
      </div>
      <div className="footer__credits">
        <span className="flat-line"></span>
        <small className="footer__credits__name">
          Gemaakt door
          <a
            href="https://www.svcreatio.be"
            target="__blank"
            rel="noopener noreferrer"
          >
            SV Creatio Comm. V.
          </a>
        </small>
      </div>
    </footer>
  )
}

export default Footer
