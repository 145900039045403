import React from "react"

import "./layout.css"
import Header from "./header"
import Footer from "./footer"
import PropTypes from "prop-types"

const Layout = ({
  children,
  useWhiteHeader,
  useBlueBackground,
  useHeaderBackground,
}) => {
  return (
    <>
      <Header
        useWhiteHeader={useWhiteHeader}
        useBlueBackground={useBlueBackground}
        useHeaderBackground={useHeaderBackground}
      />
      <main className={`${useBlueBackground ? "blue-background" : ""}`}>
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  useWhiteHeader: false,
  useBlueBackground: false,
  useHeaderBackground: true,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  useWhiteHeader: PropTypes.bool,
  useBlueBackground: PropTypes.bool,
  useHeaderBackground: PropTypes.bool,
}

export default Layout
