import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "./header.css"
import logo from "../images/benner.svg"
import whiteLogo from "../images/benner-white.svg"
import instagram from "../images/instagram-black.svg"
import instagramWhite from "../images/instagram.svg"
import instagramWhiteSmall from "../images/instagram-small.svg"
import { Link } from "gatsby"

const Header = ({ useWhiteHeader, useBlueBackground, useHeaderBackground }) => {
  const [isMenuActive, setMenuActive] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [isOverlappigWithContact, setOverlappingWithContact] = useState(false)

  const toggleMenu = () => {
    setMenuActive(!isMenuActive)
  }

  useEffect(() => {
    const scrollElement = document.getElementById("gatsby-focus-wrapper")
    const contactElement = document.querySelector(".contact")
    const handleScroll = () => {
      const isScrolled = scrollElement.scrollTop > 50
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }

      if (!contactElement) return
      const overlapsContact =
        scrollElement.scrollTop >= contactElement.offsetTop &&
        scrollElement.scrollTop <=
          contactElement.offsetTop + contactElement.clientHeight

      if (overlapsContact !== isOverlappigWithContact) {
        setOverlappingWithContact(overlapsContact)
      }
    }

    scrollElement.addEventListener("scroll", handleScroll, {
      passive: true,
    })

    return () => {
      scrollElement.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled, isOverlappigWithContact])

  return (
    <header>
      <div
        className={`header-container ${scrolled ? "-scrolled" : ""} ${
          useWhiteHeader ? "-white" : ""
        } ${useBlueBackground ? "-blue-background" : ""} ${
          useHeaderBackground ? "" : "-no-background-color"
        }`}
      >
        <a href="/">
          <img
            src={
              useBlueBackground || isOverlappigWithContact ? whiteLogo : logo
            }
            alt="Logo benner"
          />
        </a>
        <button
          aria-label="menu"
          className={`menu ${scrolled || useWhiteHeader ? "-white" : ""}`}
          onClick={() => toggleMenu()}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav>
          <ul>
            <li>
              <Link to="/lien" activeClassName="active">
                Lien
              </Link>
            </li>
            <li>
              <Link to="/annelies" activeClassName="active">
                Annelies
              </Link>
            </li>
            <li>
              <a
                href="https://www.instagram.com/benner.nu"
                target="__blank"
                rel="noopener noreferrer"
              >
                <img
                  src={useWhiteHeader ? instagramWhiteSmall : instagram}
                  alt="Instagram logo"
                />
              </a>
            </li>
          </ul>
          <ul></ul>
        </nav>
      </div>
      <div
        className={`mobile-header-container ${isMenuActive ? "active" : ""}`}
      >
        <div className="header">
          <a href="/">
            <img src={whiteLogo} alt="Logo benner" />
          </a>
          <button
            aria-label="Sluit menu"
            className="close-menu"
            onClick={() => toggleMenu()}
          >
            <span></span>
            <span></span>
          </button>
        </div>
        <nav className="main-nav">
          <ul>
            <li>
              <Link to="/" activeClassName="active">
                benner.
              </Link>
            </li>
            <li>
              <Link to="/lien" activeClassName="active">
                Lien.
              </Link>
            </li>
            <li>
              <Link to="/annelies" activeClassName="active">
                Annelies.
              </Link>
            </li>
          </ul>
        </nav>
        <nav className="social-menu">
          <ul>
            <li>
              <a
                href="https://www.instagram.com/benner.nu"
                target="__blank"
                rel="noopener noreferrer"
              >
                <img src={instagramWhite} alt="Instagram logo" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.defaultProps = {
  useWhiteHeader: false,
  useBlueBackground: false,
  useHeaderBackground: false,
}

Header.propTypes = {
  useWhiteHeader: PropTypes.bool,
  useBlueBackground: PropTypes.bool,
  useHeaderBackground: PropTypes.bool,
}

export default Header
